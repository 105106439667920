import { Tooltip } from "antd";

const ParthnerTokensCard = ({ currentPT }) => {
  const {
    name,
    symbol,
    contractAddress,
    decimals,
    totalTokens,
    blockchain,
    tokenType,
    native,
    status,
    createdAt,
    updatedAt,
    worldwide,
    socialHub,
    totalTokensClaimed,
    radius,
  } = currentPT;

  const infoH = [
    {
      title: "Contract address",
      value: contractAddress,
      description:
        "The unique address of the smart contract on the blockchain where this token is issued.",
    },
  ];

  const info = [
    {
      title: "Name",
      value: name,
      description:
        "The name of the token used to identify the project or asset.",
    },
    {
      title: "Symbol",
      value: symbol,
      description:
        "A short symbol or abbreviation representing the token (e.g., BTC for Bitcoin).",
    },
    {
      title: "Radius",
      value: radius,
      description: "The radius.",
    },

    {
      title: "Blockchain",
      value: blockchain,
      description:
        "The name of the blockchain where this token operates (e.g., Ethereum, Binance Smart Chain).",
    },
    {
      title: "Total tokens",
      value: totalTokens,
      description:
        "The total number of tokens that exist or can be created under this contract.",
    },
    {
      title: "Total claimed tokens",
      value: totalTokensClaimed,
      description: "The total number of claimed tokens.",
    },
    {
      title: "Token type",
      value: tokenType,
      description:
        "The type of token, such as ERC-20, BEP-20, or other standards.",
    },
    {
      title: "Worldwide",
      value: worldwide,
      description:
        "The current worldwide status of the token, such as active, inactive, or suspended.",
    },
    {
      title: "Decimals",
      value: decimals,
      description:
        "The number of decimal places that can be used to divide the token.",
    },

    {
      title: "socialHub",
      value: socialHub,
      description:
        "The current socialHub status of the token, such as active, inactive, or suspended.",
    },
    {
      title: "Is native",
      value: native ? "Yes" : "No",
      description:
        "Indicates whether this token is native to the blockchain (e.g., ETH is native to Ethereum).",
    },
    {
      title: "Status",
      value: status,
      description:
        "The current status of the token, such as active, inactive, or suspended.",
    },

    {
      title: "Created at",
      value: new Date(createdAt).toLocaleString(),
      description: "The date and time when this token or contract was created.",
    },
    {
      title: "Updated at",
      value: new Date(updatedAt).toLocaleString(),
      description:
        "The last date and time when the token or contract information was updated.",
    },
  ];

  return (
    <div>
      <div className="modaltitle">Information:</div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          paddingTop: 20,
          gap: 16,
        }}
      >
        {infoH.map((el) => (
          <div
            key={el.title}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid gray",
              cursor: "pointer",
            }}
          >
            <Tooltip title={el.description} placement="bottom">
              <div style={{ fontSize: 10, color: "gray" }}>{el.title}:</div>
              <div style={{ fontSize: 16, color: "black" }}>{el.value}</div>
            </Tooltip>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          width: "100%",
          padding: "20px 0",
          gap: "16px",
        }}
      >
        {info.map((el) => (
          <div
            key={el.title}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid gray",
              cursor: "pointer",
            }}
          >
            <Tooltip title={el.description} placement="bottom">
              <div style={{ fontSize: 10, color: "gray" }}>{el.title}:</div>
              <div style={{ fontSize: 16, color: "black" }}>{el.value}</div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParthnerTokensCard;
