import React, { useEffect, useRef, useState } from "react";
import { Spin, Switch } from "antd";
import { ErrorMessage, Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import CustomTextInput from "../CustomTextInput";
import { UploadImage } from "../UploadImage";
import Select, { components } from "react-select";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <img
        src={props.data.iconImgUrl}
        alt=""
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      {props.data.symbol}
    </components.Option>
  );
};

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <img
        src={props.data.iconImgUrl}
        alt=""
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      {props.data.symbol}
    </components.SingleValue>
  );
};

const AddOrEditParthner = ({
  handleCloseModal,
  isFirstTime,
  setIsFirstTime,
  currentPT,
  isEdit,
  page,
}) => {
  const { createParthnerLoading, editParthnerLoading } = useSelector(
    (state) => state.SocialHubReducer
  );
  const BoastImageLink = useSelector(
    (state) => state?.BoostReducer?.uploadBoastimageAllData?.result?.imgLink
  );
  const { tokens } = useSelector((state) => state.TokensReducer);

  const tokensArray = (tokens && tokens) || [];
  const loading = createParthnerLoading || editParthnerLoading;
  const [imageFile, setImageFile] = useState(null);
  const [rewardObj, setRewardObj] = useState();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const handleResetForm = () => {
    setImageFile(null);
    formikRef.current.resetForm({
      values: {
        name: "",
        description: "",
        partnerLogo: "",
        tokenId: "",
      },
    });
  };

  useEffect(() => {
    if (isEdit) {
      formikRef.current.setFieldValue("name", currentPT.name);
      formikRef.current.setFieldValue("description", currentPT.description);
      formikRef.current.setFieldValue("partnerLogo", currentPT.partnerLogo);
      setRewardObj(tokensArray.find((item) => item._id === currentPT?.tokenId));
    } else {
      formikRef.current.setFieldValue("name", "");
      formikRef.current.setFieldValue("description", "");
      formikRef.current.setFieldValue("partnerLogo", "");
      setRewardObj(null);
    }
    dispatch({
      type: types.GetTokensRequest,
      payload: {
        searchText: "",
        page: 1,
      },
    });
  }, [currentPT]);

  return (
    <div>
      <div className="modaltitle">{`${isEdit ? "Edit" : "Add"}`} Partner</div>
      <Formik
        key="my-form"
        initialValues={{
          name: "",
          description: "",
          partnerLogo: "",
          tokenId: "",
        }}
        enableReinitialize={false}
        innerRef={formikRef}
        validationSchema={Constants.AddParthnerSchema}
        onSubmit={(values, { resetForm }) => {
          if (isEdit) {
            const updatedBody = {
              partnerId: currentPT._id,
              name: values.name,
              description: values.description,
              tokenId: rewardObj._id,
            };
            dispatch({
              type: types.EditParthnerRequest,
              payload: {
                updatedBody,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                  setRewardObj(null);
                },
                page,
              },
            });
          } else {
            const data = {
              name: values.name,
              description: values.description,
              partnerLogo: !!imageFile ? BoastImageLink : "",
              tokenId: rewardObj._id,
            };
            dispatch({
              type: types.CreateParthnerRequest,
              payload: {
                data,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                  setRewardObj(null);
                },
                page,
              },
            });
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <div className="formContainer">
            <div className="formHeader">
              <div className="formFields">
                <div
                  style={{
                    display: "flex",
                    gap: 32,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <UploadImage
                    setImageFile={setImageFile}
                    ErrorMessage={ErrorMessage}
                    setIsFirstTime={setIsFirstTime}
                    isFirstTime={isFirstTime}
                    formikRef={formikRef}
                    isEdit={isEdit}
                    currentPool={{ icon: currentPT?.partnerLogo }}
                    loading={loading}
                  />
                  <div
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <Field name="name">
                      {({ meta }) => (
                        <CustomTextInput
                          label={"Name"}
                          type="text"
                          error={meta.touched ? meta.error : null}
                          onChange={(e) => {
                            if (e.target.value.length <= 25)
                              handleChange("name")(e);
                          }}
                          placeHolderText={"Enter name..."}
                          value={values.name}
                          onBlur={handleBlur("name")}
                          showEyeIcon={false}
                          required={true}
                          disable={loading}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <Field name="description">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Description"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 750)
                          handleChange("description")(e);
                      }}
                      placeHolderText={"Enter description..."}
                      value={values.description}
                      onBlur={handleBlur("description")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
                <div>
                  <label className="d-flex">Token/NFT*</label>
                  <Select
                    onChange={(e) => {
                      setRewardObj(e);
                    }}
                    value={rewardObj}
                    placeholder="Select token/NFT..."
                    options={tokensArray}
                    getOptionLabel={(option) => option.symbol}
                    getOptionValue={(option) => option._id}
                    components={{
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        "&:hover": { borderColor: "black" },
                        border: "1px solid #9d895f",
                        borderRadius: "12px",
                        boxShadow: "none",
                      }),
                    }}
                    isDisabled={loading}
                  />
                </div>
              </div>
            </div>

            <div className="formFooter">
              <button
                type="submit"
                disabled={loading}
                className="addbtn border p1060"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <Spin className="spin" />}
                {isEdit ? "Edit" : "Add"}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="cancelbtn border p1060"
                onClick={() => {
                  if (!isEdit) {
                    handleResetForm();
                  }
                  handleCloseModal();
                  setRewardObj(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditParthner;
