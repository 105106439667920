import { Tooltip } from "antd";

const CompanyPoolCard = ({ currentPool }) => {
  const {
    _id,
    company,
    rewardObj,
    info,
    totalProducts,
    rewardQuantity,
    radius,
    countryCode,
    city,
    longitude,
    latitude,
    rewardTypes,
    status,
    createdAt,
    updatedAt,
    worldwide,
  } = currentPool;

  const arrayH = [
    {
      title: "Info",
      value: info,
      description:
        "Additional information about the pool, including location and timestamp.",
    },
  ];

  const array = [
    {
      title: "Pool ID",
      value: _id,
      description: "Unique identifier of the reward pool.",
    },
    {
      title: "Company ID",
      value: company,
      description: "Identifier of the company that owns this pool.",
    },
    {
      title: "Reward Object ID",
      value: rewardObj,
      description: "Identifier of the reward object associated with this pool.",
    },
    {
      title: "Total Products",
      value: totalProducts,
      description: "Total number of available products in this pool.",
    },
    {
      title: "Reward Quantity",
      value: rewardQuantity,
      description: "Number of available rewards in this pool.",
    },
    {
      title: "Reward Type",
      value: rewardTypes,
      description: "Type of reward, such as partner tokens.",
    },
    {
      title: "Radius",
      value: radius,
      description: "Operating radius of this pool in kilometers.",
    },
    {
      title: "Location",
      value: `${city}, ${countryCode}`,
      description: "City and country where this pool is available.",
    },
    {
      title: "Latitude",
      value: latitude,
      description: "Geographical coordinates of the pool's location.",
    },
    {
      title: "Longitude",
      value: longitude,
      description: "Geographical coordinates of the pool's location.",
    },

    {
      title: "Status",
      value: status,
      description: "Pool status (e.g., active or inactive).",
    },
    {
      title: "Worldwide Availability",
      value: worldwide,
      description: "Indicates whether the pool is available globally.",
    },
    {
      title: "Created At",
      value: new Date(createdAt).toLocaleString(),
      description: "Date and time when the pool was created.",
    },
    {
      title: "Updated At",
      value: new Date(updatedAt).toLocaleString(),
      description: "Date and time of the last pool update.",
    },
  ];

  return (
    <div>
      <div className="modaltitle">Information:</div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          paddingTop: 20,
          gap: 16,
        }}
      >
        {arrayH.map((el) => (
          <div
            key={el.title}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid gray",
              cursor: "pointer",
            }}
          >
            <Tooltip title={el.description} placement="bottom">
              <div style={{ fontSize: 10, color: "gray" }}>{el.title}:</div>
              <div style={{ fontSize: 16, color: "black" }}>{el.value}</div>
            </Tooltip>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          width: "100%",
          padding: "20px 0",
          gap: "16px",
        }}
      >
        {array.map((el) => (
          <div
            key={el.title}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid gray",
              cursor: "pointer",
            }}
          >
            <Tooltip title={el.description} placement="bottom">
              <div style={{ fontSize: 10, color: "gray" }}>{el.title}:</div>
              <div style={{ fontSize: 16, color: "black" }}>{el.value}</div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyPoolCard;
