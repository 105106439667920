import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Row,
  Col,
  Button,
  Typography,
  Table,
  Pagination,
  Tooltip,
} from "antd";
import StatisticCard from "../../../SharedComponents/StatisticCard";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../store/action_types";
import Details from "./WalletDetails";
import { DownloadOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { GET } from "../../../Utils/httpServices";
import { message } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";

const dateFormat = "YYYY/MM/DD";
const { Title, Paragraph } = Typography;
const token = window.sessionStorage.getItem("token");

const Wallet = () => {
  const { allWalletsData, loading } = useSelector((state) => ({
    allWalletsData: state.GameWalletsReducer.allWalletsData,
    loading: state.GameWalletsReducer.allWalletsLoading,
  }));

  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [csvLinkVisible, setCsvLinkVisible] = useState(false);

  const fetchData = async () => {
    try {
      const response = await GET("admin/user-stat/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const responseData = response.data.result.userStat[0];
        const transformedData = [
          {
            key: "Total Payments",
            value: responseData.totalPayment,
            image: "ShoppingImage",
          },
          {
            key: "Total Money For Boost",
            value: responseData.totalMoneyForBoost,
            image: "CreditCardImage",
          },
          {
            key: "Total Users",
            value: responseData.totalUsers,
            image: "TeamImage",
          },
        ];
        setStatsData(transformedData);
        dispatch({
          type: types.SetAllWalletsLoading,
          payload: false,
        });
      } else {
        dispatch({
          type: types.SetAllWalletsLoading,
          payload: false,
        });
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error in fetching data from the API:", error);
      dispatch({
        type: types.SetAllWalletsLoading,
        payload: false,
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const handleBackClick = () => {
    setSelectedUserId(null);
  };

  const handleMoreDetailsClick = (userId) => {
    setSelectedUserId(userId);
  };

  const handleFromDateChange = async (date) => {
    setFromDate(date);
    const modifiedFromDate = new Date(date.startOf("day").toDate());
    const modifiedToDate = toDate
      ? new Date(toDate.endOf("day").toDate())
      : null;

    if (modifiedToDate && modifiedFromDate > modifiedToDate) {
      message.error("Start date must be less than or equal to end date");
      return;
    }

    dispatch({
      type: types.GetAllWalletsRequest,
      payload: {
        page: currentPage,
        pageSize,
        fromDate: modifiedFromDate,
        toDate: modifiedToDate,
      },
    });
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
    const modifiedToDate = new Date(date.endOf("day").toDate());
    const modifiedFromDate = fromDate
      ? new Date(fromDate.startOf("day").toDate())
      : null;
    if (modifiedFromDate && modifiedFromDate > modifiedToDate) {
      message.error("End date must be greater than or equal to start date");
      return;
    }
    dispatch({
      type: types.GetAllWalletsRequest,
      payload: {
        page: currentPage,
        pageSize,
        fromDate: modifiedFromDate,
        toDate: modifiedToDate,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: types.GetAllWalletsRequest,
      payload: { page: 1, pageSize },
    });
  }, [dispatch]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const modifiedFromDate = fromDate
      ? new Date(fromDate.startOf("day").toDate())
      : null;
    const modifiedToDate = toDate
      ? new Date(toDate.endOf("day").toDate())
      : null;

    const payload = {
      page,
      pageSize,
      fromDate: modifiedFromDate,
      toDate: modifiedToDate,
    };

    dispatch({
      type: types.GetAllWalletsRequest,
      payload,
    });
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const downloadCSV = () => {
    if (currentPage <= 0) {
      message.error("Invalid page number");
      return;
    }

    const dataToDownload = allWalletsData?.result?.docs || [];

    if (dataToDownload.length === 0) {
      message.info("No data available to download");
      return;
    }

    const formattedData = dataToDownload.map((item) => ({
      "Transaction Date": item.transactionDate,
      User: item.user && item.user.nickName ? item.user.nickName : "N/A",
      Type: item.itemType,
      "Total Amount": item.totalAmount ? `$${item.totalAmount}` : "1",
      From: item.from ? item.from : "N/A",
      To: item.to ? item.to : "N/A",
    }));

    const csvContent = [
      Object.keys(formattedData[0]).join(","),
      ...formattedData.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "wallet_transactions.csv";
    link.click();
  };

  const columns = [
    {
      title: "Transaction Data",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (text) => {
        const formattedDate = text ? new Date(text).toLocaleString() : "";
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user) => (
        <span>{user && user.nickName ? user.nickName : "N/A"}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "itemType",
      key: "itemType",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text, record) => (
        <span>{record.totalAmount ? `$${record.totalAmount}` : "1"}</span>
      ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (item) => (
        <Tooltip title={item && item}>
          {`${item ? `${item.substring(0, 6)}...${item.slice(-6)}` : "N/A"}`}
        </Tooltip>
      ),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (item) => (
        <Tooltip title={item && item}>
          {`${item ? `${item.substring(0, 6)}...${item.slice(-6)}` : "N/A"}`}
        </Tooltip>
      ),
    },
    {
      key: "moreDetails",
      render: (_, record) => (
        <span
          href="#"
          style={{
            color: "#4D96FF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleMoreDetailsClick(record.user._id)}
        >
          More details
        </span>
      ),
    },
  ];

  return (
    <div style={{ padding: "auto" }}>
      {loading ? (
        <Spin indicator={<LoadingIndicator />} />
      ) : selectedUserId ? (
        <Details userId={selectedUserId} onBackClick={handleBackClick} />
      ) : (
        <>
          <Title level={3}>Wallets</Title>
          <StatisticCard data={statsData} />
          <Row gutter={16} style={{ marginTop: "24px" }}>
            <Col span={12}>
              <Title level={4}>Billing and Invoicing</Title>
              <Paragraph>Pick an account plan</Paragraph>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px",
            }}
          >
            <Col span={6}>
              <Row>
                <Title level={4} style={{ marginBottom: 8 }}>
                  From
                </Title>
                <DatePicker
                  placeholder="Start Date..."
                  className="w-100"
                  name="startDate"
                  format={dateFormat}
                  disabledDate={disabledDate}
                  onChange={handleFromDateChange}
                  value={fromDate}
                />
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Title level={4} style={{ marginBottom: 8 }}>
                  To
                </Title>
                <DatePicker
                  placeholder="End Date..."
                  className="w-100"
                  name="endDate"
                  format={dateFormat}
                  onChange={handleToDateChange}
                  value={toDate}
                  disabledDate={disabledDate}
                />
              </Row>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                onClick={downloadCSV}
                type="primary"
                style={{
                  backgroundColor: "#BAA068",
                  color: "white",
                  width: "159px",
                  height: "48px",
                }}
              >
                Download <DownloadOutlined style={{ marginLeft: "8px" }} />
              </Button>
            </Col>
          </Row>
          <Table
            pagination={false}
            dataSource={allWalletsData?.result?.docs || []}
            columns={columns || []}
          />

          <div className="d-flex justify-content-end mt20">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={allWalletsData?.result?.totalDocs}
              onChange={(page) => handlePageChange(page)}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Wallet;

const LoadingIndicator = () => (
  <div
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#78633A",
      textAlign: "center",
    }}
  >
    <Spin size="medium" />
    <div style={{ marginTop: "10px" }}>Loading Wallet Transactions......</div>
  </div>
);
