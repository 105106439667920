import { types } from "../../../store/action_types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Modal, Switch, Pagination } from "antd";
import SearchBarPro from "../../../SharedComponents/SearchBarPro";
import AddOrEditCompany from "../../../SharedComponents/AddOrEditCompany";
import AddOrEditPool from "../../../SharedComponents/AddOrEditPool";
import ViewProducts from "../../../SharedComponents/ViewProducts";
import { LeftOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
  MapIcon,
} from "../../../SharedComponents/icons/icons";
import CompanyPoolCard from "../../../SharedComponents/CompanyPoolCard";

const ModalType = {
  ADD_COMPANY: "addCompany",
  EDIT_COMPANY: "editCompany",
  ADD_POOL: "addPool",
  EDIT_POOL: "editPool",
  PRODUCTS: "products",
  POOL_INFO: "poolInfo",
};

const CompaniesManagement = () => {
  const { confirm } = Modal;
  const [modal, setModal] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentPool, setCurrentPool] = useState(null);
  const [currentCompanyForEdit, setCurrentCompanyForEdit] = useState(null);
  const {
    companies,
    pools,
    companiesLoading,
    poolsLoading,
    deleteCompanyLoading,
    deletePoolLoading,
    pagination,
  } = useSelector((state) => state.CompaniesManagementReducer);
  const dispatch = useDispatch();

  const selectedCompany = !!currentCompany;
  const loading = selectedCompany
    ? poolsLoading || deletePoolLoading
    : companiesLoading || deleteCompanyLoading;
  const isOpenProductModal = modal === ModalType.PRODUCTS;

  const nftData = useSelector(
    (state) =>
      state.CollectionReducer?.allNFTCollectionData?.result?.nftCollection?.docs
  );

  const { tokens } = useSelector((state) => state.TokensReducer);

  const nftCollection = (nftData && nftData) || [];
  const tokensArray = (tokens && tokens) || [];

  const handlDeleteRecord = (isCompany, id, name) => {
    confirm({
      title: `Delete "${name}" ${isCompany ? "company" : "pool"}`,
      content: `Are you sure to delete this ${isCompany ? "company" : "pool"}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk() {
        if (isCompany) {
          dispatch({
            type: types.DeleteCompanyRequest,
            payload: {
              companyId: id,
              page,
            },
          });
        } else {
          dispatch({
            type: types.DeletePoolRequest,
            payload: {
              poolId: id,
              currentCompanyId: currentCompany._id,
              page,
            },
          });
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const poolsColumns = [
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      width: 50,
      render: (item) => <img src={item} className="taskavatarimage" />,
    },
    {
      title: "Name",
      dataIndex: "info",
      key: "info",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Total",
      dataIndex: "totalProducts",
      key: "totalProducts",
      width: 50,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Radius",
      dataIndex: "radius",
      key: "radius",
      width: 50,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Quantity",
      dataIndex: "",
      key: "rewardQuantity",
      width: 50,
      render: (item) => <div>{item.rewardQuantity}</div>,
    },
    {
      title: "Type",
      dataIndex: "rewardTypes",
      key: "rewardTypes",
      width: 100,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Token/NFT",
      dataIndex: "",
      key: "",
      width: 100,
      render: (item) => {
        const obj =
          item.rewardTypes === "parthner-tokens"
            ? tokensArray.find((i) => i._id === item?.rewardObj)
            : nftCollection.find((i) => i._id === item?.rewardObj);
        if (item.rewardTypes === "ch-coins" || !obj) return <div>-</div>;
        const data =
          item.rewardTypes === "parthner-tokens"
            ? { name: obj?.symbol, icon: obj?.iconImgUrl }
            : { name: obj?.companyName, icon: obj?.imageLink };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={data.icon}
              alt="blas"
              style={{ width: 20, height: 20, marginRight: 10 }}
            />
            {data.name}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      width: 50,
      render: (item) => (
        <Switch
          onClick={() => {}}
          checked={item.status === "active"}
          disabled
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      width: 120,
      render: (item) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={item._id}
          className="d-flex"
        >
          <div
            className="cursor"
            onClick={() => {
              setCurrentPool(item);
              setModal(ModalType.POOL_INFO);
            }}
          >
            <InfoCircleTwoTone style={{ fontSize: "20px", paddingTop: 2 }} />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              setCurrentPool(item);
              setModal(ModalType.PRODUCTS);
            }}
          >
            <MapIcon />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              setCurrentPool(item);
              setModal(ModalType.EDIT_POOL);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              handlDeleteRecord(false, item._id, item.info);
            }}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const companyColumns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      width: 40,
      render: (item) => <img src={item} className="taskavatarimage" />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Count of pools",
      dataIndex: "countOfPools",
      key: "countOfPools",
      width: 150,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      width: 90,
      render: (item) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={item._id}
          className="d-flex"
        >
          <div
            className="ml10 cursor"
            onClick={() => {
              setCurrentCompanyForEdit(item);
              setModal(ModalType.EDIT_COMPANY);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              handlDeleteRecord(true, item._id, item.name);
            }}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const handleOpenAddModal = () => {
    if (selectedCompany) {
      setModal(ModalType.ADD_POOL);
    } else {
      setModal(ModalType.ADD_COMPANY);
    }
  };

  const handleCloseModal = () => {
    if (selectedCompany) {
      setCurrentPool(null);
      dispatch({
        type: types.ClearProductArray,
      });
    } else {
      setCurrentCompany(null);
      setCurrentCompanyForEdit(null);
    }
    setModal(null);
    setIsFirstTime(true);
  };

  const handleBackClick = () => {
    dispatch({ type: types.ClearPoolsArray });
    setCurrentCompany(null);
    setPage(1);
    setSearchText("");
  };

  const handleOnRowClick = (record) => {
    if (!selectedCompany) {
      return {
        onClick: () => {
          setCurrentCompany(record);
          setSearchText("");
        },
        style: { cursor: "pointer" },
      };
    }
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    if (!selectedCompany) {
      dispatch({
        type: types.GetCompaniesRequest,
        payload: {
          page,
          searchText,
        },
      });
    } else {
      dispatch({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompany._id,
          page,
          searchText,
        },
      });
    }
  };

  const handleChangePage = (page) => {
    if (!selectedCompany) {
      dispatch({
        type: types.GetCompaniesRequest,
        payload: {
          page,
          searchText,
        },
      });
    } else {
      dispatch({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompany._id,
          page,
          searchText,
        },
      });
    }
    setPage(page);
  };

  useEffect(() => {
    if (!selectedCompany) {
      dispatch({
        type: types.GetCompaniesRequest,
        payload: {
          page,
        },
      });
    } else {
      dispatch({
        type: types.GetPoolsRequest,
        payload: {
          id: currentCompany._id,
          page: 1,
        },
      });
      setPage(1);
    }
  }, [currentCompany]);

  useEffect(() => {
    if (!!currentPool && isOpenProductModal) {
      dispatch({
        type: types.GetProductsRequest,
        payload: currentPool._id,
      });
    }
  }, [currentPool, modal]);

  useEffect(() => {
    if (!!currentCompany) {
      dispatch({
        type: types.GetTokensRequest,
        payload: {
          searchText: "",
          page: 1,
        },
      });
      dispatch({
        type: types.GetAllNFTCollectionRequest,
        payload: {
          searchText: "",
          page: 1,
        },
      });
    }
  }, [currentCompany]);

  return (
    <div className="relative">
      {currentCompany && (
        <div className="companyHeader">
          <div className="backButton" onClick={handleBackClick}>
            <LeftOutlined />
            <div>Back</div>
          </div>
        </div>
      )}

      <div className="header">
        <div className="headerContent">
          <h2>
            {!selectedCompany ? "Companies" : `${currentCompany.name}'s Pools`}
          </h2>
          <SearchBarPro
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
          />
        </div>
        <div className="addbtn" onClick={handleOpenAddModal}>
          Add {selectedCompany ? "Pool" : "Company"} <PlusIcon />
        </div>
      </div>

      <Table
        pagination={false}
        columns={selectedCompany ? poolsColumns : companyColumns}
        dataSource={selectedCompany ? pools : companies}
        loading={loading}
        onRow={handleOnRowClick}
      />

      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={page}
          pageSize={pagination?.itemsPerPage || limit}
          total={pagination?.totalDocs || 0}
          onChange={(page) => handleChangePage(page)}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title={false}
        footer={false}
        centered={true}
        open={!!modal && !isOpenProductModal && modal !== ModalType.POOL_INFO}
        onCancel={handleCloseModal}
        width={selectedCompany ? 880 : 500}
      >
        {selectedCompany ? (
          <AddOrEditPool
            handleCloseModal={handleCloseModal}
            setIsFirstTime={setIsFirstTime}
            isFirstTime={isFirstTime}
            currentCompanyId={currentCompany?._id}
            currentPool={currentPool}
            isEdit={modal === ModalType.EDIT_POOL}
            page={page}
          />
        ) : (
          <AddOrEditCompany
            handleCloseModal={handleCloseModal}
            setIsFirstTime={setIsFirstTime}
            isFirstTime={isFirstTime}
            currentCompany={currentCompanyForEdit}
            isEdit={modal === ModalType.EDIT_COMPANY}
            page={page}
          />
        )}
      </Modal>
      <Modal
        title={false}
        footer={false}
        centered={true}
        open={isOpenProductModal}
        onCancel={handleCloseModal}
        width={880}
      >
        <ViewProducts
          handleCloseModal={handleCloseModal}
          currentPool={currentPool}
          page={page}
        />
      </Modal>
      <Modal
        title={false}
        footer={false}
        centered={true}
        open={modal === ModalType.POOL_INFO}
        onCancel={handleCloseModal}
        width={600}
      >
        <CompanyPoolCard currentPool={currentPool} />
      </Modal>
    </div>
  );
};

export default CompaniesManagement;
