import React, { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import CustomTextInput from "../CustomTextInput";

const AddOrEditParthnerTask = ({
  handleCloseModal,
  currentTask,
  isEdit,
  page,
  currentParthnerId,
}) => {
  const { createParthnerLoading, editParthnerLoading } = useSelector(
    (state) => state.SocialHubReducer
  );

  const loading = createParthnerLoading || editParthnerLoading;
  const [taskType, setTaskType] = useState();

  const formikRef = useRef();
  const dispatch = useDispatch();

  const handleResetForm = () => {
    formikRef.current.resetForm({
      values: {
        taskType: "",
        description: "",
        xpReward: "",
        handler: "",
      },
    });
  };

  useEffect(() => {
    if (isEdit) {
      formikRef.current.setFieldValue("description", currentTask.description);
      formikRef.current.setFieldValue("handler", currentTask.handler);
      formikRef.current.setFieldValue("xpReward", currentTask.xpReward);
      setTaskType({
        id: currentTask.taskType,
        title: currentTask.taskType,
      });
    } else {
      formikRef.current.setFieldValue("description", "");
      formikRef.current.setFieldValue("handler", "");
      formikRef.current.setFieldValue("xpReward", "");
      setTaskType(null);
    }
  }, [currentTask]);

  return (
    <div>
      <div className="modaltitle">
        {`${isEdit ? "Edit" : "Add"}`} Partner Task
      </div>
      <Formik
        key="my-form"
        initialValues={{
          xpReward: "",
          handler: "",
        }}
        enableReinitialize={false}
        innerRef={formikRef}
        validationSchema={Constants.AddTaskSchema}
        onSubmit={(values, { resetForm }) => {
          if (isEdit) {
            const updatedBody = {
              taskId: currentTask._id,
              xpReward: values.xpReward,
            };
            dispatch({
              type: types.EditParthnerTaskRequest,
              payload: {
                updatedBody,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                  setTaskType(null);
                },
                page,
                currentParthnerId,
              },
            });
          } else {
            const data = {
              partnerId: currentParthnerId,
              taskType: taskType.id,
              xpReward: values.xpReward,
              handler: values.handler,
            };
            dispatch({
              type: types.CreateParthnerTaskRequest,
              payload: {
                data,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                  setTaskType(null);
                },
                page,
                currentParthnerId,
              },
            });
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <div className="formContainer">
            <div className="formHeader">
              <div className="formFields">
                <div style={{ paddingBottom: 10 }}>
                  <label className="d-flex">Task Type</label>
                  <Select
                    onChange={(e) => {
                      setTaskType(e);
                    }}
                    value={taskType}
                    placeholder="Select task type..."
                    options={[
                      { title: "Twitter Like", id: "Twitter Like" },
                      { title: "Twitter Retweet", id: "Twitter Retweet" },
                      { title: "Twitter Follow", id: "Twitter Follow" },
                      { title: "Telegram Join", id: "Telegram Join" },
                      { title: "Discord Join", id: "Discord Join" },
                    ]}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    styles={{
                      control: (base) => ({
                        ...base,
                        "&:hover": { borderColor: "black" },
                        border: "1px solid #9d895f",
                        borderRadius: "12px",
                        boxShadow: "none",
                      }),
                    }}
                    isDisabled={loading || isEdit}
                  />
                </div>
                <Field name="handler">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Handler"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 500)
                          handleChange("handler")(e);
                      }}
                      placeHolderText={"Enter handler..."}
                      value={values.handler}
                      onBlur={handleBlur("handler")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading || isEdit}
                    />
                  )}
                </Field>
                <Field name="xpReward">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"XP Reward"}
                      type="number"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 75)
                          handleChange("xpReward")(e);
                      }}
                      placeHolderText={"Enter XP Reward..."}
                      value={values.xpReward}
                      onBlur={handleBlur("xpReward")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
              </div>
            </div>

            <div className="formFooter">
              <button
                type="submit"
                disabled={loading || !taskType}
                className="addbtn border p1060"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <Spin className="spin" />}
                {isEdit ? "Edit" : "Add"}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="cancelbtn border p1060"
                onClick={() => {
                  if (!isEdit) {
                    handleResetForm();
                  }
                  handleCloseModal();
                  setTaskType(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditParthnerTask;
