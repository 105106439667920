import { types } from "../action_types";

const INITIAL_STATE = {
  parthnersLoading: false,
  tasksLoading: false,
  createParthnerLoading: false,
  editParthnerLoading: false,
  deleteParthnerLoading: false,
  createTaskLoading: false,
  editTaskLoading: false,
  deleteTaskLoading: false,
  parthners: null,
  tasks: null,
  pagination: null,
};

const SocialHubReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GetParthnersRequest:
      return { ...state, parthnersLoading: true };
    case types.GetParthnersRequestSuccess:
      return {
        ...state,
        parthners: payload.parthners,
        pagination: payload.pagination,
        parthnersLoading: false,
      };
    case types.GetParthnersRequestFailure:
      return { ...state, parthnersLoading: false };

    case types.CreateParthnerRequest:
      return { ...state, createParthnerLoading: true };
    case types.CreateParthnerRequestSuccess:
      return { ...state, createParthnerLoading: false };
    case types.CreateParthnerRequestFailure:
      return { ...state, createParthnerLoading: false };

    case types.EditParthnerRequest:
      return { ...state, editParthnerLoading: true };
    case types.EditParthnerRequestSuccess:
      return { ...state, editParthnerLoading: false };
    case types.EditParthnerRequestFailure:
      return { ...state, editParthnerLoading: false };

    case types.DeleteParthnerRequest:
      return { ...state, deleteParthnerLoading: true };
    case types.DeleteParthnerRequestSuccess:
      return { ...state, deleteParthnerLoading: false };
    case types.DeleteParthnerRequestFailure:
      return { ...state, deleteParthnerLoading: false };

    case types.GetParthnerTasksRequest:
      return { ...state, tasksLoading: true };
    case types.GetParthnerTasksRequestSuccess:
      return {
        ...state,
        tasks: payload.tasks,
        pagination: payload.pagination,
        tasksLoading: false,
      };
    case types.GetParthnerTasksRequestFailure:
      return { ...state, tasksLoading: false };

    case types.EditParthnerTaskRequest:
      return { ...state, editTaskLoading: true };
    case types.EditParthnerTaskRequestSuccess:
      return { ...state, editTaskLoading: false };
    case types.EditParthnerTaskRequestFailure:
      return { ...state, editTaskLoading: false };

    case types.CreateParthnerTaskRequest:
      return { ...state, createTaskLoading: true };
    case types.CreateParthnerTaskRequestSuccess:
      return { ...state, createTaskLoading: false };
    case types.CreateParthnerTaskRequestFailure:
      return { ...state, createTaskLoading: false };

    case types.DeleteParthnerTaskRequest:
      return { ...state, deleteTaskLoading: true };
    case types.DeleteParthnerTaskRequestSuccess:
      return { ...state, deleteTaskLoading: false };
    case types.DeleteParthnerTaskRequestFailure:
      return { ...state, deleteTaskLoading: false };

    case types.ClearTasksArray:
      return { ...state, tasks: null };

    default:
      return state;
  }
};
export default SocialHubReducer;
