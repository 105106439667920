import { types } from "../../../store/action_types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Modal, Pagination } from "antd";
// import SearchBarPro from "../../../SharedComponents/SearchBarPro";
import { message } from "antd";
import { LeftOutlined, DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
} from "../../../SharedComponents/icons/icons";
import AddOrEditParthner from "../../../SharedComponents/AddOrEditParthner";
import AddOrEditParthnerTask from "../../../SharedComponents/AddOrEditParthnerTask";
import { POST } from "../../../Utils/httpServices";

const ModalType = {
  ADD_PARTHNER: "addParthner",
  EDIT_PARTHNER: "editParthner",
  ADD_TASK: "addTask",
  EDIT_TASK: "editTask",
};

const SocialHub = () => {
  const { confirm } = Modal;
  const [modal, setModal] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  // const [searchText, setSearchText] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [currentParthner, setCurrentParthner] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [currentParthnerForEdit, setCurrentParthnerForEdit] = useState(null);

  const {
    parthners,
    tasks,
    parthnersLoading,
    tasksLoading,
    deleteParthnerLoading,
    deleteTaskLoading,
    pagination,
  } = useSelector((state) => state.SocialHubReducer);

  const dispatch = useDispatch();

  const selectedParthner = !!currentParthner;
  const loading = selectedParthner
    ? tasksLoading || deleteTaskLoading
    : parthnersLoading || deleteParthnerLoading;

  const handleGetReport = async (partnerId) => {
    try {
      const response = await POST("admin/social-hub/partnerReport", {
        partnerId,
      });

      const data = response?.data?.result?.formattedReports;
      if (!data || data.length === 0) {
        message.info("No data available to download");
        return;
      }
      const taskTypes = new Set();
      data.forEach((item) => {
        item.completedTasks?.forEach((task) => taskTypes.add(task.taskType));
      });
      const headers = ["NickName", "Email", ...Array.from(taskTypes)];
      const formattedData = data.map((item) => {
        const userInfo = item.completedTasks?.[0]?.userInfo || {};
        const row = {
          NickName: userInfo.nickName || "❌",
          Email: userInfo.email || "❌",
        };

        taskTypes.forEach((taskType) => {
          const task = item.completedTasks?.find(
            (task) => task.taskType === taskType
          );
          if (task) {
            let socialNick = "";

            if (
              taskType === "Twitter Follow" ||
              taskType === "Twitter Retweet"
            ) {
              socialNick = task?.userInfo?.twitterUsername || "";
            } else if (taskType === "Discord Join") {
              socialNick = task?.userInfo?.discordUsername || "";
            }

            row[taskType] = socialNick ? `✅ (${socialNick})` : "✅";
          } else {
            row[taskType] = "❌";
          }
        });

        return row;
      });

      const csvContent = [
        headers.join(","),
        ...formattedData.map((row) => headers.map((h) => row[h]).join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "completed_tasks.csv";
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  const handlDeleteRecord = (isParthner, id, name) => {
    confirm({
      title: `Delete "${name}" ${isParthner ? "parthner" : "task"}`,
      content: `Are you sure to delete this ${
        isParthner ? "parthner" : "task"
      }?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk() {
        if (isParthner) {
          dispatch({
            type: types.DeleteParthnerRequest,
            payload: {
              partnerId: id,
              page,
            },
          });
        } else {
          dispatch({
            type: types.DeleteParthnerTaskRequest,
            payload: {
              taskId: id,
              currentParthnerId: currentParthner._id,
              page,
            },
          });
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const tasksColumns = [
    {
      title: "Type",
      dataIndex: "taskType",
      key: "taskType",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "xpReward",
      dataIndex: "xpReward",
      key: "xpReward",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Link",
      dataIndex: "handler",
      key: "handler",
      render: (item) => (
        <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
          {item}
        </div>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      width: 120,
      render: (item) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={item._id}
          className="d-flex"
        >
          <div
            className="ml10 cursor"
            onClick={() => {
              setCurrentTask(item);
              setModal(ModalType.EDIT_TASK);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              handlDeleteRecord(false, item._id, item.taskType);
            }}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const parthnersColumns = [
    {
      title: "",
      dataIndex: "partnerLogo",
      key: "partnerLogo",
      width: 40,
      render: (item) => <img src={item} className="taskavatarimage" />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Descriprion",
      dataIndex: "description",
      key: "description",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      width: 90,
      render: (item) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={item._id}
          className="d-flex"
        >
          <div
            className="ml10 cursor"
            onClick={() => {
              handleGetReport(item._id);
            }}
          >
            <DownloadOutlined style={{ fontSize: 20 }} />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              setCurrentParthnerForEdit(item);
              setModal(ModalType.EDIT_PARTHNER);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              handlDeleteRecord(true, item._id, item.name);
            }}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const handleOpenAddModal = () => {
    if (selectedParthner) {
      setModal(ModalType.ADD_TASK);
    } else {
      setModal(ModalType.ADD_PARTHNER);
    }
  };

  const handleCloseModal = () => {
    if (selectedParthner) {
      setCurrentTask(null);
    } else {
      setCurrentParthner(null);
      setCurrentParthnerForEdit(null);
    }
    setModal(null);
    setIsFirstTime(true);
  };

  const handleBackClick = () => {
    dispatch({ type: types.ClearParthnerTasksArray });
    setCurrentParthner(null);
    setPage(1);
    // setSearchText("");
  };

  const handleOnRowClick = (record) => {
    if (!selectedParthner) {
      return {
        onClick: () => {
          setCurrentParthner(record);
          // setSearchText("");
        },
        style: { cursor: "pointer" },
      };
    }
  };

  // const handleSearch = (searchText) => {
  //   setSearchText(searchText);
  //   if (!selectedParthner) {
  //     dispatch({
  //       type: types.GetCompaniesRequest,
  //       payload: {
  //         page,
  //         searchText,
  //       },
  //     });
  //   } else {
  //     dispatch({
  //       type: types.GetPoolsRequest,
  //       payload: {
  //         id: currentParthner._id,
  //         page,
  //         searchText,
  //       },
  //     });
  //   }
  // };

  const handleChangePage = (page) => {
    if (!selectedParthner) {
      dispatch({
        type: types.GetParthnersRequest,
        payload: {
          page,
        },
      });
    } else {
      dispatch({
        type: types.GetParthnerTasksRequest,
        payload: {
          id: currentParthner._id,
          page,
        },
      });
    }
    setPage(page);
  };

  useEffect(() => {
    if (!selectedParthner) {
      dispatch({
        type: types.GetParthnersRequest,
        payload: {
          page,
        },
      });
    } else {
      dispatch({
        type: types.GetParthnerTasksRequest,
        payload: {
          id: currentParthner._id,
          page: 1,
        },
      });
      setPage(1);
    }
  }, [currentParthner]);

  return (
    <div className="relative">
      {currentParthner && (
        <div className="companyHeader">
          <div className="backButton" onClick={handleBackClick}>
            <LeftOutlined />
            <div>Back</div>
          </div>
        </div>
      )}

      <div className="header">
        <div className="headerContent">
          <h2>
            {!selectedParthner ? "Partners" : `${currentParthner.name}'s tasks`}
          </h2>
          {/* <SearchBarPro
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
          /> */}
        </div>
        <div className="addbtn" onClick={handleOpenAddModal}>
          Add {selectedParthner ? "Task" : "Partner"} <PlusIcon />
        </div>
      </div>

      <Table
        pagination={false}
        columns={selectedParthner ? tasksColumns : parthnersColumns}
        dataSource={selectedParthner ? tasks : parthners}
        loading={loading}
        onRow={handleOnRowClick}
      />

      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={page}
          pageSize={pagination?.itemsPerPage || limit}
          total={pagination?.totalDocs || 0}
          onChange={(page) => handleChangePage(page)}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title={false}
        footer={false}
        centered={true}
        open={!!modal}
        onCancel={handleCloseModal}
        width={500}
      >
        {selectedParthner ? (
          <AddOrEditParthnerTask
            handleCloseModal={handleCloseModal}
            currentParthnerId={currentParthner?._id}
            currentTask={currentTask}
            isEdit={modal === ModalType.EDIT_TASK}
            page={page}
          />
        ) : (
          <AddOrEditParthner
            handleCloseModal={handleCloseModal}
            setIsFirstTime={setIsFirstTime}
            isFirstTime={isFirstTime}
            currentPT={currentParthnerForEdit}
            isEdit={modal === ModalType.EDIT_PARTHNER}
            page={page}
          />
        )}
      </Modal>
    </div>
  );
};

export default SocialHub;
