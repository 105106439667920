import { useEffect, useState } from "react";
import { ReloadOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Table, Modal, Pagination, Switch } from "antd";
import dayjs from "dayjs";

import {
  DeleteIcon,
  EditIcon,
  PlusIcon,
} from "../../../SharedComponents/icons/icons";
import { types } from "../../../store/action_types";

import SearchBarPro from "../../../SharedComponents/SearchBarPro";
import AddOrEditTokens from "../../../SharedComponents/AddOrEditTokens";
import ParthnerTokensCard from "../../../SharedComponents/ParthnerTokensCard";

const ModalType = {
  ADD: "add",
  EDIT: "edit",
  INFO: "info",
};

const Tokens = () => {
  const { confirm } = Modal;

  const { tokens, getLoading, deleteLoading, pagination } = useSelector(
    (state) => state.TokensReducer
  );
  const dispatch = useDispatch();

  const [modal, setModal] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [currentPT, setCurrentPT] = useState(null);

  const loading = getLoading || deleteLoading;
  const tokensColums = [
    {
      title: "",
      dataIndex: "iconImgUrl",
      key: "iconImgUrl",
      width: 40,
      render: (item) => <img src={item} className="taskavatarimage" />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      width: 100,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Total Tokens",
      dataIndex: "totalTokens",
      key: "totalTokens",
      width: 150,
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Claimed %",
      dataIndex: "",
      key: "totalTokensClaimed",
      width: 110,
      render: (item) => {
        const { totalTokens: total, totalTokensClaimed: claimed } = item;

        if (!claimed) return <div>-</div>;

        const percentage = (claimed / total) * 100;

        let formatted;
        if (percentage < 1) {
          const match = percentage.toString().match(/0\.(0*)(\d)/);
          if (match) {
            const zeros = match[1];
            const firstDigit = match[2];
            formatted = `0.${zeros}${firstDigit}`;
          } else {
            formatted = percentage.toFixed(5);
          }

          if (parseFloat(formatted) === 0) {
            formatted = "0.00001";
          }
        } else {
          formatted = percentage.toFixed(2);
        }

        return <div>{formatted}%</div>;
      },
    },
    {
      title: "SocialHub / Worldwide / Status",
      dataIndex: "",
      key: "socialHub",
      width: 250,
      render: (item) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Switch
            onClick={() => {}}
            checked={item.socialHub === "active"}
            disabled
          />
          <Switch
            onClick={() => {}}
            checked={item.worldwide === "active"}
            disabled
          />
          <Switch
            onClick={() => {}}
            checked={item.status === "active"}
            disabled
          />
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      width: 90,
      render: (item) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={item._id}
          className="d-flex"
        >
          <div
            className="ml10 cursor"
            onClick={() => {
              handleOpenInfoModal(item);
            }}
          >
            <InfoCircleTwoTone style={{ fontSize: "20px", paddingTop: 2 }} />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              handleOpenEditModal(item);
            }}
          >
            <EditIcon />
          </div>
          <div
            className="ml10 cursor"
            onClick={() => {
              handlDeleteRecord(item._id, item.name);
            }}
          >
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  const handlDeleteRecord = (id, name) => {
    confirm({
      title: `Delete "${name}" parthner tokens`,
      content: `Are you sure to delete this Partner Tokens?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk() {
        dispatch({
          type: types.DeleteTokensRequest,
          payload: {
            tokenId: id,
            page,
          },
        });
      },
      onCancel() {},
    });
  };
  const handleOpenAddModal = () => {
    setModal(ModalType.ADD);
  };
  const handleOpenEditModal = (record) => {
    setCurrentPT(record);
    setModal(ModalType.EDIT);
  };
  const handleOpenInfoModal = (record) => {
    setCurrentPT(record);
    setModal(ModalType.INFO);
  };
  const handleCloseModal = () => {
    setCurrentPT(null);
    setModal(null);
    setIsFirstTime(true);
  };
  const handleSearch = (searchText) => {
    setSearchText(searchText);
    dispatch({
      type: types.GetTokensRequest,
      payload: {
        page,
        searchText,
      },
    });
  };
  const handleChangePage = (page) => {
    dispatch({
      type: types.GetTokensRequest,
      payload: {
        page,
        searchText,
      },
    });

    setPage(page);
  };

  useEffect(() => {
    dispatch({
      type: types.GetTokensRequest,
      payload: {
        page,
      },
    });
  }, [refresh]);

  return (
    <div className="relative">
      <div className="header">
        <div className="headerContent">
          <h2>Partner Tokens</h2>
          <SearchBarPro
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 16,
          }}
        >
          <div
            className="addbtn"
            onClick={() => {
              setRefresh((prev) => prev + 1);
            }}
          >
            <ReloadOutlined />
          </div>
          <div className="addbtn" onClick={handleOpenAddModal}>
            Add Partner Tokens <PlusIcon />
          </div>
        </div>
      </div>

      <Table
        pagination={false}
        columns={tokensColums}
        dataSource={tokens}
        loading={loading}
      />

      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={page}
          pageSize={pagination?.itemsPerPage || limit}
          total={pagination?.totalDocs || 0}
          onChange={(page) => handleChangePage(page)}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title={false}
        footer={false}
        centered={true}
        open={modal === ModalType.INFO}
        onCancel={handleCloseModal}
        width={600}
      >
        <ParthnerTokensCard currentPT={currentPT} />
      </Modal>

      <Modal
        title={false}
        footer={false}
        centered={true}
        open={!!modal && modal !== ModalType.INFO}
        onCancel={handleCloseModal}
        width={500}
      >
        <AddOrEditTokens
          handleCloseModal={handleCloseModal}
          setIsFirstTime={setIsFirstTime}
          isFirstTime={isFirstTime}
          currentPT={currentPT}
          isEdit={modal === ModalType.EDIT}
          page={page}
        />
      </Modal>
    </div>
  );
};

export default Tokens;
