import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { GET, POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

function* GetParthnersSaga({ payload }) {
  const { page } = payload;
  try {
    const parthnersAllData_response = yield GET(
      `admin/social-hub/getPartners?page=${page}&limit=${5}`
    );

    const { docs, currentPage, itemsPerPage, totalDocs, totalPages } =
      parthnersAllData_response?.data.result.result;

    const parthners = docs;
    const pagination = {
      currentPage,
      itemsPerPage,
      totalDocs,
      totalPages,
    };

    if (parthnersAllData_response?.status === 200) {
      yield put({
        type: types.GetParthnersRequestSuccess,
        payload: { parthners, pagination },
      });
    } else {
      yield put({ type: types.GetParthnersRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.GetParthnersRequestFailure });
  }
}

function* CreateParthnerSaga({ payload }) {
  let { data, handleCloseModal, page } = payload;

  try {
    const createdParthnerData_response = yield POST(
      "admin/social-hub/addPartner",
      data
    );

    if (createdParthnerData_response?.status === 200) {
      yield put({
        type: types.CreateParthnerRequestSuccess,
      });
      yield put({
        type: types.GetParthnersRequest,
        payload: { page },
      });
      CustomToster({
        type: "success",
        message: "Partner Created Successfully",
      });
    } else {
      yield put({ type: types.CreateParthnerRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.CreateParthnerRequestFailure });
  }
}

function* EditParthnerSaga({ payload }) {
  let { updatedBody, handleCloseModal, page } = payload;

  try {
    const editParthnerData_response = yield POST(
      "admin/social-hub/editPartner",
      updatedBody
    );

    if (editParthnerData_response?.status === 200) {
      yield put({
        type: types.EditParthnerRequestSuccess,
      });

      yield put({
        type: types.GetParthnersRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Partner Edited Successfully",
      });
    } else {
      yield put({ type: types.EditParthnerRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.EditParthnerRequestFailure });
  }
}

function* DeleteParthnerSaga({ payload }) {
  const { partnerId, page } = payload;

  try {
    const deleteParthnerData_response = yield POST(
      `admin/social-hub/deletePartner`,
      {
        partnerId,
      }
    );

    if (deleteParthnerData_response?.status === 200) {
      yield put({
        type: types.DeleteParthnerRequestSuccess,
      });

      yield put({
        type: types.GetParthnersRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Partner Deleted Successfully",
      });
    } else {
      yield put({ type: types.DeleteParthnerRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.DeleteParthnerRequestFailure });
  }
}

function* GetTasksSaga({ payload }) {
  const { id, page } = payload;
  try {
    const tasks_response = yield GET(
      `admin/social-hub/getTasks?page=${page}&limit=${5}&partnerId=${id}`
    );

    const { docs, currentPage, itemsPerPage, totalDocs, totalPages } =
      tasks_response?.data.result.result;

    const tasks = docs;
    const pagination = {
      currentPage,
      itemsPerPage,
      totalDocs,
      totalPages,
    };

    if (tasks_response?.status === 200) {
      yield put({
        type: types.GetParthnerTasksRequestSuccess,
        payload: { tasks, pagination },
      });
    } else {
      yield put({ type: types.GetParthnerTasksRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.GetParthnerTasksRequestFailure });
  }
}

function* CreateTaskSaga({ payload }) {
  let { data, handleCloseModal, currentParthnerId, page } = payload;

  try {
    const createdTaskData_response = yield POST(
      "admin/social-hub/addTask",
      data
    );

    if (createdTaskData_response?.status === 200) {
      yield put({
        type: types.CreateParthnerTaskRequestSuccess,
      });

      yield put({
        type: types.GetParthnerTasksRequest,
        payload: {
          id: currentParthnerId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Task Created Successfully",
      });
    } else {
      yield put({ type: types.CreateParthnerTaskRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.CreateParthnerTaskRequestFailure });
  }
}

function* EditTaskSaga({ payload }) {
  let { updatedBody, handleCloseModal, currentParthnerId, page } = payload;

  try {
    const editTaskData_response = yield POST(
      "admin/social-hub/editTask",
      updatedBody
    );

    if (editTaskData_response?.status === 200) {
      yield put({
        type: types.EditParthnerTaskRequestSuccess,
      });

      yield put({
        type: types.GetParthnerTasksRequest,
        payload: {
          id: currentParthnerId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Task Edited Successfully",
      });
    } else {
      yield put({ type: types.EditParthnerTaskRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.EditParthnerTaskRequestFailure });
  }
}

function* DeleteTaskSaga({ payload }) {
  const { taskId, currentParthnerId, page } = payload;

  try {
    const deleteTaskData_response = yield POST(`admin/social-hub/deleteTask`, {
      taskId,
    });

    if (deleteTaskData_response?.status === 200) {
      yield put({
        type: types.DeleteParthnerTaskRequestSuccess,
      });

      yield put({
        type: types.GetParthnerTasksRequest,
        payload: {
          id: currentParthnerId,
          page,
        },
      });

      CustomToster({
        type: "success",
        message: "Task Deleted Successfully",
      });
    } else {
      yield put({ type: types.DeleteParthnerTaskRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details || e?.message,
    });
    yield put({ type: types.DeleteParthnerTaskRequestFailure });
  }
}

export default function* parthnersManagementSaga() {
  yield takeLatest(types.GetParthnersRequest, GetParthnersSaga);
  yield takeLatest(types.CreateParthnerRequest, CreateParthnerSaga);
  yield takeLatest(types.EditParthnerRequest, EditParthnerSaga);
  yield takeLatest(types.DeleteParthnerRequest, DeleteParthnerSaga);

  yield takeLatest(types.GetParthnerTasksRequest, GetTasksSaga);
  yield takeLatest(types.CreateParthnerTaskRequest, CreateTaskSaga);
  yield takeLatest(types.EditParthnerTaskRequest, EditTaskSaga);
  yield takeLatest(types.DeleteParthnerTaskRequest, DeleteTaskSaga);
}
