import React, { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { Button, Switch } from "antd";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";
import { ErrorMessage, Field, Formik } from "formik";
import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import { useDispatch, useSelector } from "react-redux";
import CustomToster from "../CustomToaster";
import CustomTextInput from "../CustomTextInput";
import GoogleMapComponent from "../../Screens/LandingScreens/Tasks/Map";
import {
  genereteUniqueName,
  getSimpleInfo,
} from "../../Utils/generetaUniqueName";
import Select, { components } from "react-select";
import { UploadImage } from "../UploadImage";
import GoogleSelect from "../GoogleSelect";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <img
        src={props.data.imageLink || props.data.iconImgUrl}
        alt=""
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      {props.data.companyName || props.data.symbol}
    </components.Option>
  );
};

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <img
        src={props.data.imageLink || props.data.iconImgUrl}
        alt=""
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      {props.data.companyName || props.data.symbol}
    </components.SingleValue>
  );
};

const AddOrEditPool = ({
  handleCloseModal,
  isFirstTime,
  setIsFirstTime,
  currentCompanyId,
  currentPool,
  isEdit,
  page,
}) => {
  const { createPoolLoading, editPoolLoading } = useSelector(
    (state) => state.CompaniesManagementReducer
  );

  const nftData = useSelector(
    (state) =>
      state.CollectionReducer?.allNFTCollectionData?.result?.nftCollection?.docs
  );

  const { tokens } = useSelector((state) => state.TokensReducer);

  const nftCollection = (nftData && nftData) || [];
  const tokensArray = (tokens && tokens) || [];

  const [rewardType, setRewardType] = useState();
  const [rewardObj, setRewardObj] = useState();
  const [clearSignal, setClearSignal] = useState(false);

  const loading = createPoolLoading || editPoolLoading;
  const [cityAndCountryData, setCityAndCountryData] = useState({
    latitude: isEdit ? currentPool.latitude : "",
    longitude: isEdit ? currentPool.longitude : "",
    country: "",
    countryCode: "",
    city: "",
  });

  const handleChangeLocationInGoogleSelect = (latitude, longitude) => {
    setCityAndCountryData((prevState) => ({
      ...prevState,
      latitude,
      longitude,
    }));
  };

  const handleClear = () => {
    setClearSignal(true);
  };

  const [imageFile, setImageFile] = useState(null);
  const [file, setFile] = useState(null);
  const formikRef = useRef();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const getCityCountryVal = (data, setFieldValue) => {
    const { city, country } = data;
    setFieldValue("selectedCity", city);
    setFieldValue("selectedCountry", country);
    setCityAndCountryData(data);
  };

  const handleLatitudeChange = (e) => {
    handleClear();
    const { value } = e.target;
    setCityAndCountryData((prev) => ({
      ...prev,
      latitude: value,
    }));
  };

  const handleLongitudeChange = (e) => {
    handleClear();
    const { value } = e.target;
    setCityAndCountryData((prev) => ({
      ...prev,
      longitude: value,
    }));
  };

  const cutFileName = (name, isFile) => {
    const fileName = name
      .split("/")
      .pop()
      .split("?")[0]
      .slice(isFile ? 13 : 0);

    if (fileName.length > 15) {
      return fileName.slice(0, 7) + "..." + fileName.slice(-5);
    } else {
      return fileName;
    }
  };

  const IconButton = (iconName) => {
    return (
      <Button
        icon={
          iconName === "EditOutlined" ? (
            <EditOutlined style={{ verticalAlign: "middle" }} />
          ) : iconName === "DownloadOutlined" ? (
            <DownloadOutlined style={{ verticalAlign: "middle" }} />
          ) : (
            <EditOutlined style={{ verticalAlign: "middle" }} />
          )
        }
        shape="circle"
        style={{
          backgroundColor:
            iconName === "PlusOutlined" ? "black" : "transparent",
          color: iconName === "PlusOutlined" ? "white" : "black",
        }}
      ></Button>
    );
  };

  const handleResetForm = () => {
    setCityAndCountryData({
      latitude: "",
      longitude: "",
      country: "",
      countryCode: "",
      city: "",
    });
    setImageFile(null);
    formikRef.current.resetForm({
      values: {
        info: "",
        image: "",
        totalProducts: "",
        radius: "",
        rewardQuantity: "",
        status: true,
      },
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      const maxSizeInMB = 150;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (fileType === "zip" || fileType === "rar") {
        if (file.size <= maxSizeInBytes) {
          setFile(file);
        } else {
          CustomToster({
            type: "error",
            message: `File size exceeds ${maxSizeInMB} MB. Please upload a smaller file.`,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      formikRef.current.setFieldValue("info", getSimpleInfo(currentPool.info));
      formikRef.current.setFieldValue("image", currentPool.image);
      formikRef.current.setFieldValue(
        "totalProducts",
        currentPool.totalProducts
      );
      formikRef.current.setFieldValue("radius", currentPool.radius);
      formikRef.current.setFieldValue(
        "rewardQuantity",
        currentPool.rewardQuantity
      );
      formikRef.current.setFieldValue(
        "status",
        currentPool.status === "active"
      );
      setCityAndCountryData({
        ...cityAndCountryData,
        latitude: currentPool.latitude,
        longitude: currentPool.longitude,
      });
      setRewardType({
        id: currentPool?.rewardTypes,
        title: currentPool?.rewardTypes,
      });
      setRewardObj(
        nftCollection.find((item) => item._id === currentPool?.rewardObj) ||
          tokensArray.find((item) => item._id === currentPool?.rewardObj)
      );
    } else {
      formikRef.current.setFieldValue("info", "");
      formikRef.current.setFieldValue("image", "");
      formikRef.current.setFieldValue("totalProducts", "");
      formikRef.current.setFieldValue("radius", "");
      formikRef.current.setFieldValue("rewardQuantity", "");
      formikRef.current.setFieldValue("status", "");
      setCityAndCountryData({
        latitude: "",
        longitude: "",
        country: "",
        countryCode: "",
        city: "",
      });
      setFile(null);
      setRewardType(null);
      setRewardObj(null);
    }
  }, [currentPool]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = currentPool.productObject;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="modaltitle">{`${isEdit ? "Edit" : "Add"}`} Pool</div>
        {isEdit && <div className="modaltitle">{currentPool.info}</div>}
        <div />
      </div>
      <Formik
        key="my-form"
        initialValues={{
          info: "",
          image: "",

          totalProducts: "",
          radius: "",
          rewardQuantity: "",

          status: "",
        }}
        enableReinitialize={false}
        innerRef={formikRef}
        validationSchema={
          !isEdit ? Constants.AddPoolSchema : Constants.EditPoolSchema
        }
        onSubmit={(values) => {
          if (!isEdit) {
            const uniqueName = genereteUniqueName(
              values.info,
              cityAndCountryData.city,
              cityAndCountryData.countryCode
            );

            let formData = new FormData();

            formData.append("companyId", currentCompanyId);
            formData.append("info", uniqueName);
            formData.append("file", imageFile);

            formData.append("countryCode", cityAndCountryData.countryCode);
            formData.append("city", cityAndCountryData.city);
            formData.append("longitude", cityAndCountryData.longitude);
            formData.append("latitude", cityAndCountryData.latitude);

            formData.append("totalProducts", values.totalProducts);
            formData.append("radius", values.radius);
            formData.append("rewardQuantity", values.rewardQuantity);

            formData.append("rewardTypes", rewardType.id);

            if (rewardType && rewardType.id !== "ch-coins") {
              formData.append("rewardObj", rewardObj._id);
            }
            formData.append("status", values.status ? "active" : "disable");

            dispatch({
              type: types.CreatePoolRequest,
              payload: {
                formData,
                handleCloseModal: () => {
                  handleResetForm();
                  handleCloseModal();
                  setFile(null);
                  setRewardType(null);
                  setRewardObj(null);
                },
                currentCompanyId,
                page,
                file,
              },
            });
          } else {
            const uniqueName = genereteUniqueName(
              values.info,
              cityAndCountryData.city,
              cityAndCountryData.countryCode
            );

            const updatedBody = {
              poolId: currentPool._id,
              info: uniqueName,
              countryCode: cityAndCountryData.countryCode,
              city: cityAndCountryData.city,
              longitude: cityAndCountryData.longitude,
              latitude: cityAndCountryData.latitude,
              totalProducts: values.totalProducts,
              radius: values.radius,
              rewardQuantity: values.rewardQuantity,
              status: values.status ? "active" : "disable",
            };

            dispatch({
              type: types.EditPoolRequest,
              payload: {
                updatedBody,
                handleCloseModal: () => {
                  handleResetForm();
                  handleCloseModal();
                  setFile(null);
                  setRewardType(null);
                  setRewardObj(null);
                },
                currentCompanyId,
                page,
                file,
              },
            });
          }
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <div className="formContainer">
            <div className="formHeader">
              <div>
                <div className="poolContainer">
                  {/* IMAGE */}

                  <UploadImage
                    setImageFile={setImageFile}
                    ErrorMessage={ErrorMessage}
                    setIsFirstTime={setIsFirstTime}
                    isFirstTime={isFirstTime}
                    formikRef={formikRef}
                    isEdit={isEdit}
                    currentPool={currentPool}
                    loading={loading}
                  />

                  <div>
                    {/* INFO */}
                    <div style={{ display: "flex", gap: 20 }}>
                      <Field name="info">
                        {({ meta }) => (
                          <CustomTextInput
                            label={"Info"}
                            type="text"
                            error={meta.touched ? meta.error : null}
                            onChange={(e) => {
                              if (e.target.value.length <= 75)
                                handleChange("info")(e);
                            }}
                            placeHolderText={"Enter info..."}
                            value={values.info}
                            onBlur={handleBlur("info")}
                            showEyeIcon={false}
                            required={true}
                            disable={loading}
                          />
                        )}
                      </Field>

                      {/* RADIUS */}
                      <div style={{ width: 93 }}>
                        <Field name="radius">
                          {({ meta }) => (
                            <CustomTextInput
                              label={"Radius"}
                              type="number"
                              error={meta.touched ? meta.error : null}
                              onChange={(e) => {
                                if (e.target.value.length <= 6) {
                                  handleChange("radius")(e);
                                }
                              }}
                              placeHolderText={"0"}
                              value={values.radius}
                              onBlur={handleBlur("radius")}
                              showEyeIcon={false}
                              disable={loading || !rewardType}
                            />
                          )}
                        </Field>
                      </div>

                      {/* TOTAL */}
                      <div style={{ width: 93 }}>
                        <Field name="totalProducts">
                          {({ meta }) => (
                            <CustomTextInput
                              label={"Total"}
                              type="number"
                              error={meta.touched ? meta.error : null}
                              onChange={(e) => {
                                if (e.target.value.length <= 6) {
                                  handleChange("totalProducts")(e);
                                }
                              }}
                              placeHolderText={"0"}
                              value={values.totalProducts}
                              onBlur={handleBlur("totalProducts")}
                              showEyeIcon={false}
                              disable={loading || !rewardType}
                            />
                          )}
                        </Field>
                      </div>

                      {/* QUANTITY & STATUS  */}
                      <div
                        style={{
                          display: "flex",
                          gap: 20,
                        }}
                      >
                        <div style={{ minWidth: 93, width: 93 }}>
                          <Field name="rewardQuantity">
                            {({ meta }) => (
                              <CustomTextInput
                                label={"Quantity"}
                                type="number"
                                error={meta.touched ? meta.error : null}
                                onChange={(e) => {
                                  if (e.target.value.length <= 6) {
                                    handleChange("rewardQuantity")(e);
                                  }
                                }}
                                placeHolderText={"0"}
                                value={values.rewardQuantity}
                                onBlur={handleBlur("rewardQuantity")}
                                showEyeIcon={false}
                                disable={loading || !rewardType}
                              />
                            )}
                          </Field>
                        </div>

                        <div
                          className="col-md-3"
                          style={{
                            margin: "0 35px",
                          }}
                        >
                          <label className="d-flex">Status*</label>

                          <div style={{ marginTop: 5 }}>
                            <Field name="status">
                              {() => (
                                <Switch
                                  checked={values.status}
                                  disabled={loading}
                                  onChange={(checked) =>
                                    setFieldValue("status", checked)
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: 20,
                      }}
                    >
                      {/* SELECT REWARD TYPE */}
                      <div style={{ width: 206 }}>
                        <label className="d-flex">Reward Type*</label>

                        <Select
                          onChange={(e) => {
                            setRewardType(e);
                            setRewardObj(null);
                          }}
                          value={rewardType}
                          placeholder="Select reward type..."
                          options={[
                            { title: "nft", id: "nft" },
                            { title: "token", id: "token" },
                            { title: "ch-coins", id: "ch-coins" },
                            { title: "parthner-tokens", id: "parthner-tokens" },
                          ]}
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.id}
                          styles={{
                            control: (base) => ({
                              ...base,
                              "&:hover": { borderColor: "black" },
                              border: "1px solid #9d895f",
                              borderRadius: "12px",
                              boxShadow: "none",
                            }),
                          }}
                          isDisabled={loading || isEdit}
                        />
                      </div>

                      {/* SELECT TOKEN/NFT */}
                      <div style={{ width: 206 }}>
                        <label className="d-flex">Token/NFT*</label>
                        <Select
                          onChange={(e) => {
                            setRewardObj(e);
                          }}
                          value={rewardObj}
                          placeholder="Select token/NFT..."
                          options={
                            rewardType?.id === "parthner-tokens"
                              ? tokensArray
                              : nftCollection
                          }
                          getOptionLabel={(option) =>
                            rewardType?.id === "parthner-tokens"
                              ? option.symbol
                              : option.companyName
                          }
                          getOptionValue={(option) => option._id}
                          components={{
                            Option: CustomOption,
                            SingleValue: CustomSingleValue,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              "&:hover": { borderColor: "black" },
                              border: "1px solid #9d895f",
                              borderRadius: "12px",
                              boxShadow: "none",
                            }),
                          }}
                          isDisabled={
                            rewardType?.id === "ch-coins" ||
                            !rewardType ||
                            loading ||
                            isEdit
                          }
                        />
                      </div>

                      {/* ADD MODEL */}
                      <div style={{ paddingBottom: 20, margin: "auto" }}>
                        <input
                          type="file"
                          accept=".rar, .zip"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          disabled={loading}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <label className="d-flex">Add Model* (opt.)</label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                              }}
                              onClick={() => fileInputRef.current.click()}
                            >
                              {file
                                ? cutFileName(file.name, false)
                                : currentPool?.productObject
                                ? cutFileName(currentPool?.productObject, true)
                                : ""}
                              {file || currentPool?.productObject
                                ? IconButton("EditOutlined")
                                : IconButton("PlusOutlined")}
                            </div>
                            {isEdit && (
                              <div onClick={handleDownload}>
                                {IconButton("DownloadOutlined")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-center">
                  <div className="flex-col">
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <GoogleSelect
                        callback={handleChangeLocationInGoogleSelect}
                        clearSignal={clearSignal}
                        resetClearSignal={() => setClearSignal(false)}
                      />
                    </div>
                    <div className="fieldContainer">
                      <Field name="city">
                        {({ meta }) => (
                          <CustomTextInput
                            label={"City"}
                            type="text"
                            error={meta.touched ? meta.error : null}
                            placeHolderText={"Chose on map..."}
                            value={cityAndCountryData.city}
                            showEyeIcon={false}
                            required={true}
                            disable
                          />
                        )}
                      </Field>
                      <Field name="country">
                        {({ meta }) => (
                          <CustomTextInput
                            label={"Country"}
                            type="text"
                            error={meta.touched ? meta.error : null}
                            placeHolderText={"Chose on map..."}
                            value={cityAndCountryData.country}
                            showEyeIcon={false}
                            required={true}
                            disable
                          />
                        )}
                      </Field>
                    </div>

                    <div className="fieldContainer">
                      <Field name="latitude">
                        {({ meta }) => (
                          <CustomTextInput
                            label={"Latitude"}
                            type="number"
                            error={meta.touched ? meta.error : null}
                            onChange={handleLatitudeChange}
                            placeHolderText={"Paste number..."}
                            value={cityAndCountryData.latitude}
                            onBlur={handleBlur("latitude")}
                            showEyeIcon={false}
                            disable={loading}
                          />
                        )}
                      </Field>
                      <Field name="longitude">
                        {({ meta }) => (
                          <CustomTextInput
                            label={"Longitude"}
                            type="number"
                            error={meta.touched ? meta.error : null}
                            onChange={handleLongitudeChange}
                            placeHolderText={"Paste number..."}
                            value={cityAndCountryData.longitude}
                            onBlur={handleBlur("longitude")}
                            showEyeIcon={false}
                            disable={loading}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="poolMap">
                    <GoogleMapComponent
                      getCityCountryVal={(data) => {
                        if (!loading) {
                          getCityCountryVal(data, setFieldValue);
                        }
                      }}
                      updateTask={true}
                      longUpdate={cityAndCountryData.longitude}
                      latUpdate={cityAndCountryData.latitude}
                      handleClear={handleClear}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="formFooter">
              <button
                type="submit"
                disabled={loading || ((!file || !rewardType) && !isEdit)}
                className="addbtn border p1060"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <Spin className="spin" />}
                {isEdit ? "Save" : "Add"}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="cancelbtn border p1060 "
                onClick={() => {
                  if (!isEdit) {
                    handleResetForm();
                  }
                  setFile(null);
                  setRewardType(null);
                  setRewardObj(null);
                  handleCloseModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Formik>

      {/* <Modal
        title={false}
        footer={false}
        centered={true}
        open={isOpenFileModal}
        onCancel={() => {
          setIsOpenFileModal();
        }}
        width={450}
      >
        <ViewFbxFile
          onClose={() => {
            setIsOpenFileModal();
          }}
          setFile={setFile}
        />
      </Modal> */}
    </div>
  );
};

export default AddOrEditPool;
